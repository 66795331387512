





























































import { defineComponent, onActivated, reactive } from "@vue/composition-api";
import { isAdmin } from "@/apps/accounts/modules/store";
import { listVM } from "../modules/store";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";
import { GenericListAPI } from "@/apps/core/api/list";

export default defineComponent({
  name: "UjianList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    UjianListBox: () => import("@/apps/ujian/components/UjianListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM, false);
    const jenisOption: Record<string, any> = {
      field: "jenis",
      value: "",
      valueMap: { "": "Jenis" },
    };

    const filterButtonConfigs: Record<string, any> = reactive([
      {
        field: "verifikasi",
        value: "",
        valueMap: {
          "": "Verifikasi",
          Belum: "Belum Verifikasi",
          Ya: "Terverifikasi",
          Tidak: "Ditolak",
        },
      },
      {
        field: "hasil",
        value: "",
        valueMap: {
          "": "Hasil",
          Belum: "Belum Lulus",
          Lulus: "Lulus",
          "Tidak Lulus": "Tidak Lulus",
        },
      },
      jenisOption,
      {
        field: "tanggalUjian",
        value: "",
        valueMap: {
          "": "Terlaksana",
          belum: "Belum",
          sudah: "Sudah",
        },
      },
    ]);

    const getJenisOptions = async () => {
      const api = new GenericListAPI("/ujian/jenis/");
      const optionArr = await api.fetch();
      const options = optionArr.reduce((preVal, curVal) => {
        preVal[curVal.id] = curVal.nama;
        return preVal;
      }, {});
      jenisOption.valueMap = Object.assign({}, jenisOption.valueMap, options);
    };
    getJenisOptions();

    let action: Record<string, any> | null = null;
    if (isAdmin.value) action = { type: "add", routeName: "ujian-add" };

    onActivated(() => {
      if (listVM.getRefreshRequest() !== "") {
        // ada permintaan refresh list (dengan initial filtering)
        listVM.applyFilter(listVM.refreshRequest.filterMap);
        if (listVM.refreshRequest.filterMap) {
          // reset filtering buttons
          for (let idx = 0; idx < filterButtonConfigs.length; idx++) {
            filterButtonConfigs[idx].value = "";
          }
          const verifikasi = listVM.refreshRequest.filterMap.verifikasi;
          const hasil = listVM.refreshRequest.filterMap.hasil;
          if (verifikasi) filterButtonConfigs[0].value = verifikasi;
          if (hasil) filterButtonConfigs[1].value = hasil;
        }
        listVM.resetRefreshRequest();
      } else if (listVM.list.length === 0) {
        // ketika listview pertama kali dipanggil, meload data list
        // asumsinya kalau list kosong, perlu fetch data dulu
        composition.onPageChange();
      }
    });

    return {
      // Data
      action,
      filterButtonConfigs,

      // Composition
      ...composition,
      ...useListFilter(),
    };
  },
});
