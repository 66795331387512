import FormModel from "@/apps/core/models/formModel";
import { Ujian, UjianAPI } from "./ujian";
import { setRefreshRequest } from "../modules/store";

export default class UjianFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new UjianAPI(),
      new Ujian(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }
}
