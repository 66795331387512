








































































































































import { debounce, has } from "lodash";
import { defineComponent, ref } from "@vue/composition-api";
import { GenericListAPI } from "@/apps/core/api/list";
import router from "@/router";
import UjianFM from "../models/ujianForm";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "UjianForm",
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isCreate = !has(router.currentRoute.params, "id");
    const requiredFields = [
      "judul",
      "residen",
      "rencanaUjian",
      "jenis",
      "pengujiSet",
    ];
    const nonReqFields = ["tanggalUjian", "mengulang"];
    const form = new UjianFM(requiredFields, nonReqFields);
    const composition = useFormModel(form, router.currentRoute.params.id);
    const ujian = composition.instance;

    const pengujiTags = ref([] as Array<Record<string, string>>);

    const getFilteredPengujiTags = async (nama: string) => {
      const url = `/anggota/konsulen/?fields_type=ac&q=${nama}`;
      const api = new GenericListAPI(url);
      const optionArr = await api.fetch();
      pengujiTags.value = optionArr.reverse();
    };

    const fetchPengujiTags = debounce(getFilteredPengujiTags, 500);

    return {
      // Data
      isCreate,
      ujian,
      pengujiTags,

      // Composition
      ...composition,

      // Method
      fetchPengujiTags,
    };
  },
});
